
<template>

  <div>
    <div class="contents">
      <div class="uk-background-fixed uk-background-center-center parallax-bg" uk-scrollspy="cls:  uk-animation-slide-bottom-small; target: .main-card ,.sub-card; delay: 300; repeat: true">
        <div class="main-card"  >
          <Breadcrumb class="uk-margin-left" />
          <div class="sub-card-top"  >
            <page-title></page-title>
            <div class="parent " >
              <div >
                <p class="text" style="font-size: 1.4rem;">
                  【中古車 スカイライン】アメリカの「25年ルール」によりGT-R R33の需要増大！
                </p>
              </div>
              <div class="button-area">
                 <a href="https://www.worldingsysdom.com/stock_info/list/car" class="btn"><span>＞＞＞在庫一覧はこちら</span></a>
              </div>
            </div>
          </div>
          <div class="sub-card"  >
            中古車市場におけるスカイラインGT-RやR33の需要は、アメリカの並行輸入車の緩和措置である通称「25年ルール」の制定で増大する一方です。その影響は日本国内の中古車市場にまで及び、往年の名車に再び注目が集まっています。
            <div class="section-title">
              <span >「25年ルール」とは？中古車市場で再び輝くGT-R R33！</span>
            </div>
            <div class="section-main " >
              <div class="uk-flex-top" uk-grid>
                  <div class="uk-width-2-3@m">
                    <p >中古車の中でもスカイラインGT-RやGT-Rニスモ、GT-R R33といったプレミアムカーを中心に取り扱う株式会社 MIX YARDです。いつかは乗りたいけど、希少価値が高くなかなか出会えない車に特化し、国産車・外車問わず幅広く取り扱っています。スカイラインなどの中古車販売はもちろん、下取り・買取もお気軽にご相談ください。</p>
                    <p >中古車市場においてスカイラインGT-RのR33が再び注目を集めていることをご存じでしょうか。R33の人気の背景には、アメリカにおける「25年ルール」が大きく関係しています。</p>
                    <p >こちらでは25年ルールとはどのような内容か、R33の人気上昇の理由も解説しますので、ぜひ参考にしてください。</p>
                    <h3 class="heading-number" data-number="◆">中古車需要を上昇させたアメリカの「25年ルール」とは？</h3>  
                    <p >アメリカでは1988年より、並行輸入車の緩和措置である通称「25年ルール」が適用されました。これは、右ハンドル車のように本来は輸入が禁止されている車でも、製造から25年以上経過しているという条件付きでクラシックカーとしての登録を許可する制度です。</p>
                    <p >アメリカの道路では、車は日本とは逆の右側通行です。日本では左ハンドル車の輸入・登録が認められていますが、アメリカやカナダなどの北米では右ハンドル車の登録は原則禁止されています。</p>
                    <p >この規制を例外的に認める制度が、25年ルールです。製造から25年以上経過していれば右ハンドル車をアメリカ国内で運転することが可能になり、また、通常求められる厳格な安全基準のテストを特別に免除されるメリットもあります。</p>
                    <p >さらに、アメリカの厳しい排ガス規制や関税が対象外になることも追い風となり、アメリカ国内におけるスカイラインの中古車や右ハンドルの日本車需要がますます向上しました。</p>
                  </div>
                  <div class="uk-width-1-3@m uk-flex-last">
                      <img src="@/assets/img/column-gtr-amg/rule-letter-magnifying-glass.jpg" alt="「25年ルール」とは？中古車市場で再び輝くスカイラインGT-R R33" width="640" height="426" style="height: auto;">
                  </div>
              </div>
              <h3 class="heading-number" data-number="◆">25年ルールにより、往年のGT-R車「R33」の人気が上昇！</h3>  
              <p >高品質で安全性に優れた日本車は、もともと海外で高い需要がありました。とりわけ、アメリカにおける日本車需要は25年ルールの制定によってますます高まり、JDM（Japanese Domestic Market）と呼ばれて人気を集めています。</p>
              <p >また、これにより、日本国内での中古車相場も上昇がとどまることを知らない勢いであることをご存じでしょうか。日本のクラシックカーの希少価値が向上したことで、往年のスポーツカーにも再び注目が集まっています。</p>
              <p >中でも関心が高まっているのが、日産GT-Rシリーズの「R33」です。</p>

              <h3 class="heading-number" data-number="◆">「R33」の特徴や人気の秘密</h3>  
              <p >カーレースで数々の記録を樹立した日産の「スカイラインGT-R」は、日本が世界に誇るスポーツカーです。初戦からスペックの高さを見せつけたスカイラインGT-Rは、その後も何度も改良を重ね、多くの偉業を達成しました。</p>
              <p >R33はそんなスカイラインGT-Rシリーズの新モデルとして、1995年に登場した型式です。グループAのツーリングカーレースで29連勝という偉業を達成した「R32」の後継車として登場したR33は、当時のファンから多くの期待を集めました。</p>
              <p >R32と比べて大型なボディを持ちながらも、ドイツのニュルブルクリンクで行われたテストドライブにおいてR32よりも21秒速いタイムを叩き出すなど、期待を裏切らない走りを見せてくれた名車です。</p>
              <p >スカイラインGT-Rはもともとアメリカでの需要が高く、特に映画「ワイルド・スピード」で主人公であるブライアン・オコナーが乗ったR34は驚くほどの高値で取引きされています。その人気の影響はR32やR33にも及び、JDMの代表格として愛されています。</p>
              <p >実は、スカイラインGT-Rは新車時に正規の海外輸出ルートが確立されていませんでした。そのため、海外では新車販売はされることなく、海外の方がR33を購入することはほぼ不可能だったのです。この希少価値の高さも、現在のR33の人気を支える理由の一つです。</p>
              <p >2020年、製造から25年が経過したR33に満を持して「25年ルール」が適用されます。需要に対して供給のなかったR33が「解禁」されるとあって、アメリカでの需要は瞬く間に増大していきました。</p>
              <div class="c-btn">
                  <router-link to="/jigyo/oldCars" class="c-btn-anchor">中古車</router-link>
              </div>
            </div>
          </div>
          <div class="sub-card"  >
            <div class="section-title">
              <span >【希少中古車】R33のアメリカ進出が日本のスカイライン市場に与える影響とは</span>
            </div>
            <div class="section-main " >
              <p>アメリカでの「25年ルール」適用により、日産スカイラインGT-R R33の人気が急上昇しています。このR33のアメリカ進出は、日本のスカイライン市場にも大きな影響を与え始めています。</p>
              <h3 class="heading-number" data-number="◆">R33価格の高騰</h3>  
              <p >アメリカでの需要増加により、R33の中古車価格は日本国内でも高騰しています。特に程度の良い個体は、数年前と比べて2倍以上の価格で販売されるケースも珍しくありません。</p>
              <h3 class="heading-number" data-number="◆">国内在庫の減少</h3>  
              <p >R33の海外流出が進むにつれ、日本国内における在庫は減少しています。特に人気の高い前期型は、すでに品薄状態となっており、今後さらに希少価値が高まることが予想されます。</p>
              <h3 class="heading-number" data-number="◆">国内におけるR33人気再燃</h3>  
              <p >アメリカでの人気を背景に、日本国内でもR33への注目度が高まっています。かつてR33を所有していた人が再び購入を検討したり、若い世代がR33の魅力に気づき始めたりといった動きが見られます。</p>
              <h3 class="heading-number" data-number="◆">スカイライン市場全体の活性化</h3>  
              <p >R33の人気は、スカイラインシリーズ全体への関心向上にもつながっています。R32やR34といった他のモデルの価格上昇や、スカイライン関連グッズの売上げ増加なども見られ、スカイライン市場全体が活性化しています。</p>
              <p >R33のアメリカ進出は、日本のスカイライン市場に大きな変化をもたらしました。今後もR33の人気は続くと予想され、価格高騰や国内在庫減少といった状況はさらに加速していく可能性があります。</p>
              <p >日本国内におけるスカイラインファンにとっては、R33のアメリカ進出は喜ばしいニュースであると同時に、複雑な思いを抱く方もいらっしゃるかもしれません。しかし、この変化をチャンスと捉え、スカイラインの魅力を再認識するきっかけにすることもできます。</p>

              <div class="c-btn">
                  <router-link to="/companyInfo" class="c-btn-anchor">car shop MIXについて</router-link>
              </div>
            </div>
          </div>
          <div class="sub-card"  >
            <div class="section-title">
              <span >希少なスカイラインGT-Rシリーズの中古車探しはcar shop MIXが徹底サポート！</span>
            </div>
            <div class="section-main " >
              <div class="uk-flex-top" uk-grid>
                  <div class="uk-width-2-3@m">
                    <p >製造後25年が経過していれば輸入禁止の車が登録可能となる「25年ルール」により、アメリカにおける日本産中古車の需要は急増しました。25年ルールが適用された車は、厳しい安全基準テストが免除になるなどメリットも多く、アメリカでの中古車市場はますますにぎわいを見せると考えられるでしょう。</p>
                    <p >そんな中、日産が開発した伝説的スポーツカー「スカイラインGT-R」のR32やR33の人気がますます高まりつつあるのです。スカイラインはもともと希少価値が高い車であるため、中古車で状態のよいものを見つけるのは簡単ではありません。</p>
                    <p >人気の日産GT-Rを購入するなら、中古車販売のcar shop MIXにお任せください。国産車・外車に関わらず、一般市場ではなかなか手に入らない限定車や希少車を幅広くラインナップしております。</p>
                    <p >在庫がない場合も、オークションの代行などによりご希望のお車をお探しすることが可能です。</p>
                    <p >まずはお気軽にお問い合わせください。</p>
                  </div>
                  <div class="uk-width-1-3@m uk-flex-last">
                    <img alt="白色のスカイライン中古車" src="@/assets/img/column-gtr-amg/white-used-car.jpg">
                  </div>
              </div>
              <div class="c-btn">
                <router-link to="/contact" class="c-btn-anchor">お問い合わせ</router-link>
              </div>
            </div>
          </div>
          <column-footer :title="'人気が高いGT-RのR32やR33をお探しなら中古車販売のcar shop MIXにご相談ください'" />
        </div>
      </div>
    </div>
  </div>
  
</template>


<script>
import Breadcrumb from "../../../modules/parts/Breadcrumb.vue";
import ColumnFooter from "./Footer.vue";
export default {
  components: {
    Breadcrumb,
    ColumnFooter
  },
}
</script>



<style scoped lang="scss">
.sub-card-top{
  background-image:url(~@/assets/img/column-gtr-amg/head-mv02.jpg);
}
.parallax-bg {
  background-image:url(~@/assets/img/bg-image3.jpg);
  
}
.access-list{
  *{
    max-height: 100%;
    max-width: 100%;
  }
  iframe{
    width: 60vw;
    height: 50vh;
  }
}


</style>
